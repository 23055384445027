import React from "react";
import {
  createViewState,
  JBrowseLinearGenomeView,
} from "@jbrowse/react-linear-genome-view";

const JBrowserViewer = () => {
  // Example genome data configuration with dummy sequence and gene annotations
  const viewState = createViewState({
    // assembly: {
    //   name: "GRCh38",
    //   sequence: {
    //     type: "ReferenceSequenceTrack",
    //     trackId: "GRCh38-ReferenceSequenceTrack",
    //     adapter: {
    //       type: "BgzipFastaAdapter",
    //       fastaLocation: {
    //         uri: "https://ipfsuploadapi.moramba.io:5123/static/data/user01/GRCh38.fa.gz",
    //         locationType: "UriLocation",
    //       },
    //       faiLocation: {
    //         uri: "https://ipfsuploadapi.moramba.io:5123/static/data/user01/GRCh38.fa.gz.fai",
    //         locationType: "UriLocation",
    //       },
    //       gziLocation: {
    //         uri: "https://ipfsuploadapi.moramba.io:5123/static/data/user01/GRCh38.fa.gz.gzi",
    //         locationType: "UriLocation",
    //       },
    //     },
    //   },
    //   aliases: ["hg38"],
    //   refNameAliases: {
    //     adapter: {
    //       type: "RefNameAliasAdapter",
    //       location: {
    //         uri: "https://ipfsuploadapi.moramba.io:5123/static/data/user01/jbrowseTextFile.txt",
    //         locationType: "UriLocation",
    //       },
    //     },
    //   },
    // },
    // tracks: [
    //   {
    //     type: "FeatureTrack",
    //     trackId:
    //       "GCA_000001405.15_GRCh38_full_analysis_set.refseq_annotation.sorted.gff",
    //     name: "NCBI RefSeq Genes",
    //     category: ["Genes"],
    //     assemblyNames: ["GRCh38"],
    //     adapter: {
    //       type: "Gff3TabixAdapter",
    //       gffGzLocation: {
    //         uri: "https://ipfsuploadapi.moramba.io:5123/static/data/user01/GCA_000001405.15_GRCh38_full_analysis_set.refseq_annotation.sorted.gff.gz",
    //         locationType: "UriLocation",
    //       },
    //       index: {
    //         location: {
    //           uri: "https://ipfsuploadapi.moramba.io:5123/static/data/user01/GCA_000001405.15_GRCh38_full_analysis_set.refseq_annotation.sorted.gff.gz.tbi",
    //           locationType: "UriLocation",
    //         },
    //         indexType: "TBI",
    //       },
    //     },
    //     renderer: {
    //       type: "SvgFeatureRenderer",
    //     },
    //   },
    //   {
    //     type: "AlignmentsTrack",
    //     trackId: "NA12878.alt_bwamem_GRCh38DH.20150826.CEU.exome",
    //     name: "NA12878 Exome",
    //     category: ["1000 Genomes", "Alignments"],
    //     assemblyNames: ["GRCh38"],
    //     adapter: {
    //       type: "CramAdapter",
    //       cramLocation: {
    //         uri: "https://ipfsuploadapi.moramba.io:5123/static/data/user01/NA12878.alt_bwamem_GRCh38DH.20150826.CEU.exome.cram",
    //         locationType: "UriLocation",
    //       },
    //       craiLocation: {
    //         uri: "https://ipfsuploadapi.moramba.io:5123/static/data/user01/NA12878.alt_bwamem_GRCh38DH.20150826.CEU.exome.cram",
    //         locationType: "UriLocation",
    //       },
    //       sequenceAdapter: {
    //         type: "BgzipFastaAdapter",
    //         fastaLocation: {
    //           uri: "https://ipfsuploadapi.moramba.io:5123/static/data/user01/GRCh38.fa.gz",
    //           locationType: "UriLocation",
    //         },
    //         faiLocation: {
    //           uri: "https://ipfsuploadapi.moramba.io:5123/static/data/user01/GRCh38.fa.gz.fai",
    //           locationType: "UriLocation",
    //         },
    //         gziLocation: {
    //           uri: "https://ipfsuploadapi.moramba.io:5123/static/data/user01/GRCh38.fa.gz.gzi",
    //           locationType: "UriLocation",
    //         },
    //       },
    //     },
    //   },
    //   {
    //     type: "VariantTrack",
    //     trackId:
    //       "ALL.wgs.shapeit2_integrated_snvindels_v2a.GRCh38.27022019.sites.vcf",
    //     name: "1000 Genomes Variant Calls",
    //     category: ["1000 Genomes", "Variants"],
    //     assemblyNames: ["GRCh38"],
    //     adapter: {
    //       type: "VcfTabixAdapter",
    //       vcfGzLocation: {
    //         uri: "https://s3.amazonaws.com/jbrowse.org/genomes/GRCh38/variants/ALL.wgs.shapeit2_integrated_snvindels_v2a.GRCh38.27022019.sites.vcf.gz",
    //         locationType: "UriLocation",
    //       },
    //       index: {
    //         location: {
    //           uri: "https://s3.amazonaws.com/jbrowse.org/genomes/GRCh38/variants/ALL.wgs.shapeit2_integrated_snvindels_v2a.GRCh38.27022019.sites.vcf.gz.tbi",
    //           locationType: "UriLocation",
    //         },
    //         indexType: "TBI",
    //       },
    //     },
    //   },
    // ],

    assembly: {
      name: "GRCh38",
      sequence: {
        type: "ReferenceSequenceTrack",
        trackId: "GRCh38-ReferenceSequenceTrack",
        adapter: {
          type: "BgzipFastaAdapter",
          fastaLocation: {
            uri: "https://jbrowse.org/genomes/hg19/fasta/hg19.fa.gz",
            locationType: "UriLocation",
          },
          faiLocation: {
            uri: "https://jbrowse.org/genomes/hg19/fasta/hg19.fa.gz.fai",
            locationType: "UriLocation",
          },
          gziLocation: {
            uri: "https://jbrowse.org/genomes/hg19/fasta/hg19.fa.gz.gzi",
            locationType: "UriLocation",
          },
        },
      },
      aliases: ["hg38"],

      refNameAliases: {
        adapter: {
          type: "RefNameAliasAdapter",
          location: {
            uri: "https://s3.amazonaws.com/jbrowse.org/genomes/hg19/hg19_aliases.txt",
            locationType: "UriLocation",
          },
        },
      },
    },
    tracks: [
      {
        type: "FeatureTrack",
        trackId:
          "GCA_000001405.15_GRCh38_full_analysis_set.refseq_annotation.sorted.gff",
        name: "NCBI RefSeq Genes",
        category: ["Genes"],
        assemblyNames: ["GRCh38"],
        adapter: {
          type: "Gff3TabixAdapter",
          gffGzLocation: {
            uri: "https://ipfsuploadapi.moramba.io:5123/static/data/Homo_sapiens.GRCh38.113.abinitio.sorted.gff3.gz",
            locationType: "UriLocation",
          },
          index: {
            location: {
              uri: "https://ipfsuploadapi.moramba.io:5123/static/data/Homo_sapiens.GRCh38.113.abinitio.sorted.gff3.gz.tbi",
              locationType: "UriLocation",
            },
            indexType: "TBI",
          },
        },
        renderer: {
          type: "SvgFeatureRenderer",
        },
      },
      {
        type: "AlignmentsTrack",
        trackId: "NA12878.alt_bwamem_GRCh38DH.20150826.CEU.exome",
        name: "NA12878 Exome",
        category: ["1000 Genomes", "Alignments"],
        assemblyNames: ["GRCh38"],
        adapter: {
          type: "CramAdapter",
          cramLocation: {
            uri: "https://s3.amazonaws.com/jbrowse.org/genomes/GRCh38/alignments/NA12878/NA12878.alt_bwamem_GRCh38DH.20150826.CEU.exome.cram",
            locationType: "UriLocation",
          },
          craiLocation: {
            uri: "https://s3.amazonaws.com/jbrowse.org/genomes/GRCh38/alignments/NA12878/NA12878.alt_bwamem_GRCh38DH.20150826.CEU.exome.cram.crai",
            locationType: "UriLocation",
          },
          sequenceAdapter: {
            type: "BgzipFastaAdapter",
            fastaLocation: {
              uri: "https://s3.amazonaws.com/jbrowse.org/genomes/GRCh38/fasta/GRCh38.fa.gz",
              locationType: "UriLocation",
            },
            faiLocation: {
              uri: "https://s3.amazonaws.com/jbrowse.org/genomes/GRCh38/fasta/GRCh38.fa.gz.fai",
              locationType: "UriLocation",
            },
            gziLocation: {
              uri: "https://s3.amazonaws.com/jbrowse.org/genomes/GRCh38/fasta/GRCh38.fa.gz.gzi",
              locationType: "UriLocation",
            },
          },
        },
      },
      {
        type: "VariantTrack",
        trackId:
          "ALL.wgs.shapeit2_integrated_snvindels_v2a.GRCh38.27022019.sites.vcf",
        name: "1000 Genomes Variant Calls",
        category: ["1000 Genomes", "Variants"],
        assemblyNames: ["GRCh38"],
        adapter: {
          type: "VcfTabixAdapter",
          vcfGzLocation: {
            uri: "https://s3.amazonaws.com/jbrowse.org/genomes/GRCh38/variants/ALL.wgs.shapeit2_integrated_snvindels_v2a.GRCh38.27022019.sites.vcf.gz",
            locationType: "UriLocation",
          },
          index: {
            location: {
              uri: "https://s3.amazonaws.com/jbrowse.org/genomes/GRCh38/variants/ALL.wgs.shapeit2_integrated_snvindels_v2a.GRCh38.27022019.sites.vcf.gz.tbi",
              locationType: "UriLocation",
            },
            indexType: "TBI",
          },
        },
      },
    ],

    location: "chr1:1-1000000", // Initial location of the genome browser
  });
  // console.log("http://s3.amazonaws.com/jbrowse.org/genomes/GRCh38/GRCh38.aliases.txt")
  //ipfsuploadapi.moramba.io:5123/static/data/user01/jbrowseTextFile.txt
  https: return (
    <div>
      {/* <h1>Genome Browser with Dummy Data</h1>x */}
      <JBrowseLinearGenomeView viewState={viewState} />
    </div>
  );
};

export default JBrowserViewer;
